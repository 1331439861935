<template>
  <a-drawer
    :title="id && !isCopy ? '修改推广链接' : '创建推广链接'"
    width="1000"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 链接名称 -->
      <a-form-item
        label="链接名称"
        name="name"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.name"
          placeholder="请输入"
          :maxlength="30"
        />
      </a-form-item>
      <!-- 客户端 -->
      <a-form-item
        label="客户端"
        name="project_platform_id"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.project_platform_id"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :disabled="!!id && !isCopy"
          @change="changePlatform"
          show-search
          :filter-option="filterOption"
        >
          <a-select-option
            v-for="item in platforms"
            :key="`${item.project_platform_id}-${item.name}`"
            :value="item.project_platform_id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 着陆页 -->
      <a-form-item
        label="着陆页"
        name="page_where"
        :rules="[{ required: true }]"
      >
        <a-radio-group
          class="form-input"
          v-model:value="formState.page_where"
          :disabled="!!id && !isCopy"
        >
          <a-radio
            v-for="item in LandingTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 短剧 -->
      <a-form-item
        v-if="formState.page_where === 1"
        label="短剧"
        name="drama_id"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          show-search
          v-model:value="formState.drama_id"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filter-option="filterOption"
          :disabled="!!id && !isCopy"
          @change="onChangeDrama"
        >
          <a-select-option
            v-for="item in dramas"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 播放起始 -->
      <a-form-item
        v-if="formState.page_where === 1"
        label="播放起始"
        name="eq_number"
        :rules="[{ required: true, validator: validatorPlayStartNo  }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.eq_number"
          placeholder="请输入"
          suffix="集起"
          :maxlength="30"
          :disabled="!formState.drama_id"
        />
      </a-form-item>
      <!-- 付费起始 -->
      <a-form-item
        v-if="formState.page_where === 1"
        label="付费起始"
        name="start_pay_eq"
        :rules="[{ required: true, validator: validatorStartPayEq  }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.start_pay_eq"
          placeholder="请输入"
          suffix="集起"
          :maxlength="30"
          :disabled="!formState.drama_id"
        />
        <template v-if="drama.start_pay_eq">
          <span style="padding: 0 10px; color: #5A5A5A;">系统设定 {{ drama.start_pay_eq }}</span>
          <a @click="touchStartPayEq">填入</a>
        </template>
      </a-form-item>
      <!-- 每集收费 -->
      <a-form-item
        v-if="formState.page_where === 1"
        label="每集收费"
        name="fee"
        :rules="[{ required: true, validator: validatorFee }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.fee"
          placeholder="请输入"
          suffix="嗨豆"
          :maxlength="30"
          :disabled="!formState.drama_id"
        />
        <template v-if="drama.fee">
          <span style="padding: 0 10px; color: #5A5A5A;">系统设定 {{ drama.fee }}</span>
          <a @click="touchFee">填入</a>
        </template>
      </a-form-item>
      <!-- 关闭充值面板露出IAA -->
      <a-form-item
        style="margin-bottom: 0px;"
        :rules="[{ required: true }]"
      >
        <template #label>
          <span>关闭充值面板露出IAA</span>
          <a-tooltip>
            <template #title>关闭充值面板是否露出IAA广告解锁。</template>
            <InfoCircleOutlined />
          </a-tooltip>
        </template>
        <a-form-item
          name="visible_iaa"
          :rules="[{ required: true }]"
        >
          <a-radio-group
            style="white-space: nowrap;"
            v-model:value="formState.visible_iaa"
          >
            <a-radio :value="0">不露出</a-radio>
            <a-radio :value="1">露出</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form-item>
      <!-- 退出播放页露出IAA -->
      <a-form-item
        style="margin-bottom: 0px;"
        :rules="[{ required: true }]"
      >
        <template #label>
          <span>退出播放页露出IAA</span>
          <a-tooltip>
            <template #title>退出播放页是否露出IAA广告解锁。</template>
            <InfoCircleOutlined />
          </a-tooltip>
        </template>
        <a-form-item
          name="quit_play_iaa"
          :rules="[{ required: true }]"
        >
          <a-radio-group
            style="white-space: nowrap;"
            v-model:value="formState.quit_play_iaa"
          >
            <a-radio :value="0">不露出</a-radio>
            <a-radio :value="1">露出</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form-item>
      <!-- 推广渠道 -->
      <a-form-item
        label="推广渠道"
        name="channel"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.channel"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :disabled="!!id && !isCopy"
          @change="changeChannel"
        >
          <template v-for="item in ChannelTypes" :key="item.value">
            <a-select-option
              v-if="item.platform.includes(formState.platform_id)"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </template>
        </a-select>
      </a-form-item>
      <!-- H5投放链路 -->
      <a-form-item
        v-if="formState.platform_id === 5"
        label="H5投放链路"
        name="launch_type"
        :rules="[{ required: true }]"
      >
        <a-radio-group
          class="form-input"
          v-model:value="formState.launch_type"
        >
          <a-radio
            v-for="item in DropLinkTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 企业客服 -->
      <a-form-item
        v-if="formState.platform_id === 5 && formState.launch_type === 1"
        label="企业客服"
        name="open_kfid"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.open_kfid"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in followList"
            :key="`${item.open_kfid}-${item.kf_name}`"
            :value="item.open_kfid"
          >
            {{ item.kf_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 巨量回传方式 -->
      <a-form-item
        v-if="formState.channel === 1"
        label="巨量回传方式"
        name="channel_type"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.channel_type"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in TTCallbackModes"
            :key="item.value"
            :value="item.value"
            :disabled="!(item.channel.includes(formState.channel) && item.platform.includes(formState.platform_id))"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 百度回传方式 -->
      <a-form-item
        v-if="formState.channel === 10"
        label="百度回传方式"
        name="channel_type"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.channel_type"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in BDCallbackModes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 回传方式 -->
      <a-form-item
        v-if="[3, 5, 8, 14, 15, 16, 17].includes(formState.channel)"
        label="回传方式"
        name="channel_type"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.channel_type"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in CallbackModes"
            :key="item.value"
            :value="item.value"
            :disabled="!(item.channel.includes(formState.channel) && item.platform.includes(formState.platform_id))"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 广告主ID -->
      <a-form-item
        v-if="formState.channel === 5 && formState.platform_id === 3"
        label="广告主ID"
        :name="['remark', 'owner_id']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.remark.owner_id"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- API-ID -->
      <a-form-item
        v-if="formState.channel === 5 && formState.platform_id === 3"
        label="API-ID"
        :name="['remark', 'app_id']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.remark.app_id"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- API-Key -->
      <a-form-item
        v-if="(formState.channel === 5 && formState.platform_id === 3) || (formState.channel === 13 && formState.platform_id === 3)"
        label="API-Key"
        :name="['remark', 'app_key']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.remark.app_key"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 账户id -->
      <a-form-item
        v-if="formState.channel === 7 && formState.platform_id === 3"
        label="账户id"
        :name="['remark', 'advertiser_id']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.remark.advertiser_id"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- src_id -->
      <a-form-item
        v-if="formState.channel === 7 && formState.platform_id === 3"
        label="src_id"
        :name="['remark', 'src_id']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.remark.src_id"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- access_token -->
      <a-form-item
        v-if="(formState.channel === 7 && formState.platform_id === 3) || (formState.channel === 13 && formState.platform_id === 3)"
        label="token"
        :name="['remark', 'access_token']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.remark.access_token"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 账户id -->
      <!-- <a-form-item
        v-if="(formState.channel === 3 && formState.platform_id === 3 && formState.channel_type === 3)"
        label="账户id"
        :name="['remark', 'account_id']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.remark.account_id"
          placeholder="请输入"
        />
      </a-form-item> -->
      <!-- 数据源id -->
      <a-form-item
        v-if="(formState.channel === 3 && formState.platform_id === 3 && formState.channel_type === 3)"
        label="数据源id"
        :name="['remark', 'click_id']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.remark.click_id"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 百度Token -->
      <a-form-item
        v-if="formState.channel === 10 && formState.platform_id === 3"
        label="百度Token"
        :name="['remark', 'baidu_token']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.remark.baidu_token"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 原始ID -->
      <a-form-item
        v-if="formState.channel === 9 && formState.platform_id === 3"
        label="小程序原始ID"
        :name="['remark', 'gh_id']"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"  
          v-model:value="formState.remark.gh_id"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 充值回传 -->
      <!-- v-if="[1, 3, 4, 5, 7, 8, 9, 10, 11].includes(formState.channel)" -->
      <a-form-item
        style="margin-bottom: 0px;"
        label="充值回传"
        :rules="[{ required: true }]"
      >
        <!-- 充值回传 -->
        <a-form-item
          name="callback_type"
          :rules="[{ required: true }]"
        >
          <a-radio-group
            style="white-space: nowrap;"
            v-model:value="formState.callback_type"
            @change="changeCallbackType"
          >
            <a-radio
              v-for="item in CallbackTypes"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
              <a-tooltip v-if="item.msg">
                <template #title>{{ item.msg }}</template>
                <info-circle-outlined />
              </a-tooltip>
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <!-- 回传比例 -->
        <a-form-item
          v-if="[2, 3, 4, 5].includes(formState.callback_type)"
          :rules="[{ required: true }]"
        >
          <div class="callback-hint" v-if="[2].includes(formState.callback_type)">请根据首充金额定义回传比例：</div>
          <div class="callback-hint" v-if="[3].includes(formState.callback_type)">请根据订单金额定义回传比例：</div>
          <div class="callback-hint" v-if="[5].includes(formState.callback_type)">请根据首充金额定义用户回传比例：</div>
          <!-- 一行 -->
          <div
            style="display: flex;"
            v-for="(item, index) in formState.callback_rule"
            :key="index"
          >
            <!-- min -->
            <a-form-item
              :name="['callback_rule', index, 'min']"
              :rules="[{ required: true, validator: (rule, value) => validatorMin(rule, value, item) }]"
            >
              <a-input
                v-model:value="item.min"
                placeholder="请输入"
                suffix="元"
                @change="changeInputMin($event, index)"
              />
            </a-form-item>
            <!-- 间距 -->
            <div style="padding: 0 10px;  flex-shrink: 0; margin-top: 4px;">-</div>
            <!-- max -->
            <a-form-item
              :name="['callback_rule', index, 'max']"
              :rules="[{ required: true, validator: (rule, value) => validatorMax(rule, value, item) }]"
            >
              <a-input
                v-model:value="item.max"
                placeholder="请输入"
                suffix="元"
                @change="changeInputMax($event, index)"
              />
            </a-form-item>
            <!-- 间距 -->
            <div style="padding: 0 10px; flex-shrink: 0; margin-top: 4px;">回传</div>
            <!-- must_num -->
            <a-form-item
              :name="['callback_rule', index, 'must_num']"
              :rules="[{ required: true, validator: (rule, value) => validatorMustNum(rule, value, item) }]"
            >
              <a-input
                v-model:value="item.must_num"
                placeholder="请输入"
                :suffix="formState.callback_type === 5 ? '人' : '笔'"
                @change="changeInputMustNum($event, index)"
              />
            </a-form-item>
            <!-- 间距 -->
            <div style="padding: 0 10px; flex-shrink: 0; margin-top: 4px;">后回传</div>
            <!-- 比例 -->
            <a-form-item
              :name="['callback_rule', index, 'percent']"
              :rules="[{ required: true, validator: validatorCallbackPercent }]"
            >
              <a-input
                style="width: 160px;"
                v-model:value="item.percent"
                placeholder="请输入回传比例"
                suffix="%"
              />
            </a-form-item>
            <!-- 删除 -->
            <div style="width: 40px; flex-shrink: 0; margin-top: 6px; text-align: center;">
              <delete-outlined
                v-if="formState.callback_rule.length > 1"
                @click="touchDelPercent(index)"
              />
            </div>
          </div>
        </a-form-item>
        <div :style="{ marginTop: ((!id && isCopy) || formState.callback_type != 1) ? '-24px' : '0px', marginBottom: '24px' }">
          <div v-if="[2, 3, 4, 5].includes(formState.callback_type)" style="margin-bottom: 10px;"><a @click="touchAddPercent">+新增区间</a></div>
          <span v-if="[2, 3, 4, 5].includes(formState.callback_type)">说明：最小金额（包含），最大金额（不包含）。若充值金额不在定义范围内则默认回传。</span>
          <a @click="touchRecord" v-if="!!id && !isCopy">操作记录</a>
        </div>
      </a-form-item>
      <!-- 默认充值模板 -->
      <a-form-item
        label="默认充值模板"
        name="charge_id"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          show-search
          v-model:value="formState.charge_id"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filter-option="filterOption"
          @mouseleave="mouseleave"
        >
          <template
            v-for="item in defaultList"
          >
            <a-select-option
              v-if="item.detail && item.detail.length"
              :key="`${item.id}-${item.name}`"
              :value="item.id"
              @mouseenter="mouseenter(item.id)"
            >
              {{ item.name }}
            </a-select-option>
          </template>
        </a-select>
        <a class="recharge-delete" @click="touchPreview(formState.charge_id)">预览</a>
      </a-form-item>
      <!-- 选中的虚拟币模板展示 -->
      <div class="coin-list" v-if="formState.charge_id && defaultList.length">
        <div
          v-for="(setting, ind) in ((defaultList.filter(item => item.id === formState.charge_id)[0] || {}).detail || [])"
          :key="ind"
          class="play-name"
        >
          <!-- 虚拟币 -->
          <template v-if="setting.type === 'coin'">
            <!-- 第一项 -->
            {{ setting.money }}元
            <span class="gap">|</span>
            <!-- 第二项 -->
            立得{{ setting.coin }}{{ defaultList.filter(item => item.id === formState.charge_id)[0].project.virtual_coin_name }}
            <!-- 第三项 -->
            <template v-if="setting.free_coin">
              <span class="gap">|</span>
              送{{ setting.free_coin }}{{ defaultList.filter(item => item.id === formState.charge_id)[0].project.virtual_coin_name }}
            </template>
          </template>
          <!-- 全集解锁 -->
          <template v-if="setting.type === 'unlock'">
            <!-- 第一项 -->
            {{ setting.money }}元
            <!-- 第二项 -->
            <a-tag class="tag-type" v-if="setting.description">辅助语：{{ setting.description }}</a-tag>
          </template>
          <!-- 五折卡、会员、全集解锁 -->
          <template v-else>
            <!-- 第一项 -->
            {{ setting.days }}天<span class="gap">|</span>
            <!-- 第二项 -->
            {{ setting.money }}元
            <!-- 第三项 -->
            <a-tag class="tag-type" v-if="setting.description">辅助语：{{ setting.description }}</a-tag>
          </template>
          <!-- 辅助语 -->
          <a-tag class="tag-type" v-if="setting.is_help_message">辅助语：{{ setting.help_message }}</a-tag>
          <!-- 角标 -->
          <a-tag class="tag-type" v-if="setting.is_corner_on">角标：{{ setting.corner_name }}</a-tag>
          <!-- 气泡 -->
          <a-tag class="tag-type" v-if="setting.is_bubble_on">气泡：{{ setting.bubble_name }}</a-tag>
          <!-- 底色突出 -->
          <a-tag class="tag-type" v-if="setting.is_highlight">底色突出</a-tag>
          <!-- 手势引导 -->
          <a-tag class="tag-type" v-if="setting.is_gesture">手势引导</a-tag>
        </div>
      </div>
      <!-- 复充模板 -->
      <a-form-item
        label="复充模板"
      >
        <div
          class="recharge-item"
          v-for="(item, index) in formState.recharge_detail"
        >
          <div class="recharge-num form-input">
            <span>充值次数≥</span>
            <div style="flex: 1;"></div>
            <a-select
              style="width: 100px; margin-left: 20px;"
              v-model:value="item.num"
              placeholder="请选择"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              :filter-option="filterOption"
              @change="reloadRechargeNums"
            >
              <a-select-option
                v-for="item in 10"
                :key="item"
                :value="item"
                :disabled="recharge_nums.includes(item)"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </div>
          <a-select
            class="form-input"
            show-search
            v-model:value="item.charge_id"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            :filter-option="filterOption"
            @mouseleave="mouseleave"
          >
            <template
              v-for="item in rechargingList"
              :key="`${item.id}-${item.name}`"
            >
              <a-select-option
                v-if="item.detail.length"
                :key="`${item.id}-${item.name}`"
                :value="item.id"
                @mouseenter="mouseenter(item.id)"
              >
                {{ item.name }}
              </a-select-option>
            </template>
          </a-select>
          <a class="recharge-delete" @click="touchDeleteRecharge(index)">删除</a>
          <a class="recharge-delete" @click="touchPreview(item.charge_id)">预览</a>
        </div>
        <div><a @click="touchAddRecharge">+ 新增条件</a></div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <!-- <a-button type="primary" @click="touchSubmit">确定</a-button> -->
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 支付模版预览 -->
    <PayPreviewModal ref="RefPayPreviewModal"></PayPreviewModal>
    <!-- 操作记录 -->
    <OperationRecordDrawer ref="RefOperationRecordDrawer"></OperationRecordDrawer>
    <!-- 预览模版 -->
    <PayPreviewView ref="RefPayPreviewView" :right="840"></PayPreviewView>
  </a-drawer>
</template>

<script setup>
import { nextTick, reactive, ref } from 'vue'
import Pub from '@/utils/public'
import Loading from '@/components/Loading'
import PayPreviewModal from '@/views/operate/config/components-coin-custom/PreviewModal'
import PayPreviewView from '@/views/operate/config/components-coin-custom/PreviewView'
import OperationRecordDrawer from '@/views/copyright/reseller/components-iaplink/OperationRecordDrawer'
import { message } from 'ant-design-vue'
import { TTCallbackModes, BDCallbackModes, CallbackModes, ChannelTypes, LandingTypes, CallbackTypes, DropLinkTypes } from '@/utils/constantList'
import { messageKfList } from '@/api/operate'
import { popularizeCreate, platformList, dramaList, chargeAll } from '@/api/reseller'
import {
  resellerUser,
  resellerDrama,
  resellerPlatformList
} from '@/api/copyright'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 支付模版预览
const RefPayPreviewModal = ref(null)
const RefPayPreviewView = ref(null)
// 操作记录
const RefOperationRecordDrawer = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 客户端
let platforms = ref([])
// 短剧列表
let dramas = ref([])
// 当前选中的短剧
let drama = ref({})
// 默认充值模版列表
let defaultList = ref([])
// 复充模版列表
let rechargingList = ref([])
// 企业微信客服列表
let followList = ref([])
// 复充模版数量
let recharge_nums = ref([])
// id
let id = ref(undefined)
// 详情数据
let detail = ref({})
// 复制创建
let isCopy = ref(false)
// 表单参数
const formState = reactive({
  // 名称
  name: undefined,
  // 落地页类型
  page_where: 1,
  // 短剧
  drama_id: undefined,
  // 播放起始
  eq_number: 1,
  // 付费起始
  start_pay_eq: undefined,
  // 每集收费
  fee: undefined,
  // 关闭充值面板露出IAA
  visible_iaa: 0,
  // 退出播放页露出IAA
  quit_play_iaa: 0,
  // 默认模板
  charge_id: undefined,
  // 复充模板
  recharge_detail: [],
  // 客户端
  project_id: undefined,
  platform_id: undefined,
  project_platform_id: undefined,
  // 推广渠道
  channel: undefined,
  // 回传方式
  channel_type: undefined,
  // 回传比例
  callback_rule: [],
  // 充值回传
  callback_type: 1,
  // H5投放链路
  launch_type: 1,
  // 配置
  remark: {
    // 广告主ID
    owner_id: undefined,
    // API-ID
    app_id: undefined,
    // API-Key
    app_key: undefined,
    // 账户id
    advertiser_id: undefined,
    // srcId
    src_id: undefined,
    // access_token
    access_token: undefined,
    // 账户ID
    account_id: undefined,
    // 数据源id
    click_id: undefined,
    // 百度 token
    baidu_token: undefined,
    // 小程序原始ID
    gh_id: undefined
  },
  // 企业客服
  open_kfid: undefined
})

// 进入元素
function mouseenter (id) {
  RefPayPreviewView.value.show(id)
}

// 离开元素
function mouseleave () {
  RefPayPreviewView.value.hide()
}

// 显示
function showDrawer (record, copy) {
  // 详情
  if (record.id) {
    // 复制创建
    isCopy.value = copy
    // 记录详情
    id.value = record.id
    detail.value = record
    // 取出参数值
    for (let i in formState) {
      const v = record[i]
      if (i === 'recharge_detail') {
        formState[i] = [].concat(v || [])
        reloadRechargeNums()
      } else if (i === 'remark') {
        formState[i].owner_id = v ? v.owner_id : undefined
        formState[i].app_id = v ? v.app_id : undefined
        formState[i].app_key = v ? v.app_key : undefined
        formState[i].advertiser_id = v ? v.advertiser_id : undefined
        formState[i].src_id = v ? v.src_id : undefined
        formState[i].access_token = v ? v.access_token : undefined
        formState[i].account_id = v ? v.account_id : undefined
        formState[i].click_id = v ? v.click_id : undefined
        formState[i].baidu_token = v ? v.baidu_token : undefined
        formState[i].gh_id = v ? v.gh_id : undefined
      } else {
        formState[i] = v
      }
    }
    // 优化显示
    if (formState.charge_id === 0) {
      formState.charge_id = undefined
    }
  } else {
    // 优化显示
    formState.drama_id = undefined
  }
  // 更新回传比例数据
  updateCallbackPercents()
  // 显示
  visible.value = true
  // 获取选择项
  getSelectList()
  // 获取短剧列表
  getDramaAll()
  // 企业微信客服列表
  getMessageKfList()
}

// 获取选择项
function getSelectList () {
  resellerPlatformList().then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      platforms.value = data
    }
  })
  // 默认模版
  chargeAll({ is_first_charge: 1 }).then(res => {
    const { code, data } = res
    if (code === 0) {
      defaultList.value = data
    }
  })
  // 复充模版
  chargeAll({ is_first_charge: 2 }).then(res => {
    const { code, data } = res
    if (code === 0) {
      rechargingList.value = data
    }
  })
}

// 切换充值回传
function changeCallbackType () {
  // 更新充值回传
  updateCallbackPercents()
}

// 新增充值回传
function touchAddPercent () {
  formState.callback_rule.push({
    min: undefined,
    max: undefined,
    must_num: 0,
    percent: undefined
  })
}

// 删除充值回传
function touchDelPercent (index) {
  formState.callback_rule.splice(index, 1)
}

// 更新充值回传
function updateCallbackPercents () {
  if (formState.callback_type == 1) {
    formState.callback_rule = []
  } else if ((formState.callback_type !== detail.value.callback_type) || !detail.value.callback_rule) {
    formState.callback_rule = [{
      min: undefined,
      max: undefined,
      must_num: 0,
      percent: undefined
    }]
  } else {
    formState.callback_rule = [].concat(detail.value.callback_rule || [])
  }
}
 
// 切换渠道
function changeChannel () {
  // 清空回传方式
  formState.channel_type = undefined
}

// 切换短剧
function onChangeDrama(e) {
  drama.value = dramas.value.find(item => item.drama_id === e) || {}
  if (formState.start_pay_eq) {
    RefForm.value.validateFields(['start_pay_eq'])
  }
  if (formState.fee) {
    RefForm.value.validateFields(['fee'])
  }
}

// 刷新复冲数量
function reloadRechargeNums () {
  recharge_nums.value.splice(0)
  formState.recharge_detail.forEach(item => {
    if (item.num) { recharge_nums.value.push(item.num) }
  })
}

// 短剧列表
function getDramaAll () {
  resellerDrama().then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
      // 同步剧集信息
      onChangeDrama(formState.drama_id)
    }
  })
}

// 企业微信客服列表
function getMessageKfList () {
  messageKfList({ project_id: Pub.IS_DEBUG() ? 18 : 2 }).then(res => {
    const { code, data } = res
    if (code === 0) {
      followList.value = data.data
    }
  })
}

// 添加复充模板
function touchAddRecharge () {
  formState.recharge_detail.push({
    num: undefined,
    charge_id: undefined
  })
}

// 移除复充模板
function touchDeleteRecharge (index) {
  formState.recharge_detail.splice(index, 1)
  reloadRechargeNums()
}

// 预览充值模版
function touchPreview(charge_id) {
  RefPayPreviewModal.value.showModal(charge_id)
}

// 填入付费起始
function touchStartPayEq () {
  formState.start_pay_eq = drama.value.start_pay_eq
  RefForm.value.validateFields(['start_pay_eq'])
}

// 填入每集收费
function touchFee () {
  formState.fee = drama.value.fee
  RefForm.value.validateFields(['fee'])
}

// 操作记录
function touchRecord () {
  RefOperationRecordDrawer.value.showDrawer(id.value)
}

// 播放起始
function validatorPlayStartNo (rule, value) {
  if (!value) {
    return Promise.reject('请输入播放起始')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (value > drama.value.total_ep) {
    return Promise.reject('不得大于短剧总集数')
  }
  return Promise.resolve()
}

// 付费起始
function validatorStartPayEq(rule, value) {
  // 否则校验自身
  if (!value) {
    return Promise.reject('请输入付费起始')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (value > drama.value.total_ep) {
    return Promise.reject('不得大于短剧总集数')
  }
  return Promise.resolve()
}

// 每集收费
function validatorFee (rule, value) {
  // 否则校验自身
  if (!value) {
    return Promise.reject('请输入每集收费')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (!Pub.IS_EVEN_NUMBER(value)) {
    return Promise.reject('必须为偶数')
  }
  return Promise.resolve()
}

// 校验 - 回传min/max
function changeInputMin (e, index) {
  RefForm.value.validateFields([['callback_rule', index, 'max']])
}
function changeInputMax (e, index) {
  RefForm.value.validateFields([['callback_rule', index, 'min']])
}
function changeInputMustNum (e, index) {
  RefForm.value.validateFields([['callback_rule', index, 'must_num']])
}
function validatorMin (rule, value, item) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (item.max && Number(value) >= Number(item.max)) {
    return Promise.reject('必须小于最大金额')
  } else if (Number(value) > 1000) {
    return Promise.reject('取值范围 0 - 1000')
  }
  return Promise.resolve()
}

function validatorMax (rule, value, item) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (item.min && Number(value) <= Number(item.min)) {
    return Promise.reject('必须大于最小金额')
  } else if (Number(value) > 1000) {
    return Promise.reject('取值范围 0 - 1000')
  }
  return Promise.resolve()
}

function validatorMustNum (rule, value, item) {
  if (!value && value !== 0) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (Number(value) > 1000) {
    return Promise.reject('取值范围 0 - 1000')
  }
  return Promise.resolve()
}

// 效验 - 回传比例
function validatorCallbackPercent (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (Number(value) > 100) {
    return Promise.reject('取值范围 0 - 100')
  }
  return Promise.resolve()
}

//  提交
function touchSubmit () {
  RefForm.value.validate().then((res => {
    // 处理复充模版数据
    const recharge_detail = formState.recharge_detail.filter((item) => {
      return item.num && item.charge_id
    })
    // 回传数值
    formState.callback_rule.forEach((item) => {
      const keys = Object.keys(item)
      keys.forEach((key) => {
        item[key] = Number(item[key])
      })
    })
    // 上传数据
    const params = {
      // 复制创建，也算正常创建
      id: isCopy.value ? undefined : id.value,
      ...formState,
      recharge_detail: recharge_detail
    }
    // 新增
    isLoading.value = true
    popularizeCreate(params).then((res) => {
      isLoading.value = false
      if (res.code === 0) {
        message.success('提交成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message)
      }
    }).catch(() => {
      isLoading.value = false
      message.error('提交失败')
    })
  })).catch((err) => {
    // console.log(err)
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    resetFields()
    formState.recharge_detail.splice(0)
    formState.name = undefined
    formState.page_where = 1
    formState.drama_id = undefined
    formState.start_pay_eq = undefined
    formState.eq_number = 1
    formState.visible_iaa = 0
    formState.quit_play_iaa = 0
    formState.fee = undefined
    formState.charge_id = undefined
    formState.recharge_detail = []
    formState.project_id = undefined
    formState.platform_id = undefined
    formState.project_platform_id = undefined
    formState.channel = undefined
    formState.channel_type = undefined
    formState.callback_rule = []
    formState.callback_type = 1
    formState.launch_type = 1
    formState.open_kfid = undefined
    formState.remark.owner_id = undefined
    formState.remark.app_id = undefined
    formState.remark.app_key = undefined
    formState.remark.advertiser_id = undefined
    formState.remark.src_id = undefined
    formState.remark.access_token = undefined
    formState.remark.account_id = undefined
    formState.remark.click_id = undefined
    formState.remark.baidu_token = undefined
    formState.remark.gh_id = undefined
    id.value = undefined
    visible.value = false
    defaultList.value = []
    recharge_nums.value = []
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
}

// 切换选中客户端
function changePlatform (e) {
  const p = platforms.value.find(item => item.project_platform_id === e)
  if (p) {
    formState.project_id = p.project_id
    formState.platform_id = p.platform_id
  }
  formState.drama_id = undefined
  formState.channel = undefined
  formState.open_kfid = undefined
  // getDramaAll()
  // getMessageKfList()
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-input {
  width: 50%;
}
.coin-list {
  background-color: #f8f8f8;
  width: 630px;
  line-height: 36px;
  padding: 16px;
  margin: 0 0 24px 124px;
}
.gap {
  margin: 0 8px;
}
.tag-type {
  margin-left: 12px;
  background: #eee;
}
.recharge-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.recharge-num {
  display: flex;
  align-items: center;
  padding-left: 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  white-space: nowrap;
  margin-right: 12px;
  flex-shrink: 1;
  /deep/ .ant-select-selector {
    border: none !important;
    border-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
  }
}
.recharge-delete {
  margin-left: 12px;
  white-space: nowrap;
}
.callback-hint {
  color: #AAA;
  margin-top: -20px;
  margin-bottom: 10px;
}
</style>